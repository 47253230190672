/* eslint-disable import/no-anonymous-default-export */

const strings = {
  common: {
    all: 'All',
    apply: 'Apply',
    author: 'Author',
    back: 'Back',
    byAuthor: 'By {author}',
    calendar: 'Calendar',
    clearAll: 'Clear All',
    close: 'Close',
    copyright: `© {name} {year}. All rights reserved.`,
    copyrightSymbol: `©`,
    custom: 'Custom',
    date: 'Date',
    homePage: 'Home',
    location: 'Where',
    menu: 'Menu',
    noResults: 'Sorry, your search produced no results',
    onThisPage: `On this page`,
    price: 'Price',
    published: 'Published',
    relatedContent: 'You might also be interested in',
    search: 'Search',
    siteName: 'Sovereign Hill',
    siteNameRtc: 'Rare Trades Centre',
    time: 'Time',
    top: 'Top',
  },
  time: {
    today: 'Today',
    week: 'This week',
    weekend: 'This weekend',
  },
  page: {
    name: 'Pages',
    singularName: 'Page',
  },
  profile: {
    name: 'Profiles',
    singularName: 'Profile',
    socialMedia: 'Social Media',
    website: 'Website',
    discipline: 'Discipline',
  },
  learning: {
    name: 'Learning Programs',
    singularName: 'Learning Program',
    viewAll: 'View all programs',
    indexLink: 'Read more',
  },
  article: {
    latest: 'Latest articles',
    name: 'Articles',
    singularName: 'Article',
    viewAll: 'View all articles',
    indexLink: 'Read more',
  },
  event: {
    latest: "What's on",
    name: 'Events',
    singularName: 'Event',
    viewAll: 'View all events',
    indexLink: "See what's on",
    notTicketed: 'Included in ticket',
    ticketed: 'Not included in ticket',
    everyday: 'Everyday',
    bookCta: 'Book your visit',
    bookNowCta: 'Book now',
    buyTicketsCta: 'Buy tickets',
    mapRef: '(map no.{ref})',
    ageGroup: `Age`,
  },
  articleCategory: {
    name: 'Categories',
    singularName: 'Category',
    allCategories: 'All Categories',
  },
  profileCategory: {
    name: 'Categories',
    singularName: 'Category',
    allCategories: 'All Categories',
  },
  learningCategory: {
    name: 'Categories',
    singularName: 'Category',
    allCategories: 'All Categories',
  },
  learningLevel: {
    name: 'Levels',
    singularName: 'Level',
    allCategories: 'All Levels',
  },
  ageGroup: {
    name: 'Ages',
    singularName: 'Age',
    allCategories: 'All Ages',
  },
  eventType: {
    name: 'Types',
    singularName: 'Type',
    allCategories: 'All Types',
  },
  eventBrand: {
    name: 'Brands',
    singularName: 'Brand',
    allCategories: 'All Brands',
  },
  eventCategory: {
    name: 'Categories',
    singularName: 'Category',
    allCategories: 'All Categories',
  },
  accessibility: {
    name: 'Accessibility',
    singularName: 'Accessibility',
    allCategories: 'All',
  },
  cta: {
    // discover: 'Find out more',
    signUp: 'Sign Up',
    more: 'More info',
  },
  search: {
    autoInputPlaceholder: 'Type to search',
    inputPlaceholder: 'Search',
    submit: 'Submit',
    viewAll: 'View all results',
    nResults: '{nResults, plural,=0 {No results} =1 {1 result} other {# results}} for "{query}"',
  },
  actions: {
    filter: 'Filter',
    filters: 'Filters',
    filterByCategory: 'Filter by category',
    sortBy: 'Sort by',
    search: 'Search',
    submit: 'Submit',
    share: 'Share',
  },
  feedback: {
    error: 'Error',
    success: 'Success',
  },
};

export default strings;
